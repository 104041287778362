<template>
    <div v-if="shouldShow()" class="py-2">
        <strong>{{ moduleName }}</strong>
        <b-btn v-b-toggle="collapseId" variant="primary" class="collapse-btn-style" @pointerdown="collapseOpen = !collapseOpen">{{ collapseIcon }}</b-btn>

        <b-collapse :id="collapseId" class="mt-2" v-on:show="refreshSliders">
            <b-card>
                <div v-for="(value, key) in parameters" :key="'div_' + key" v-if='shouldRenderParameter(key)'>

                    <div v-if="shouldRenderSettingsHeader(key)" class="py-2">
                        <strong>Settings</strong>
                        <hr>
                    </div>

                    <Parameter :param-key="key" :param-value="value" :key="key"></Parameter>
                </div>

                <div v-if="isModuleActive && isOpModeSchedule" class="py-3">
                    <strong>Schedule</strong>
                    <hr>
                    <Schedule :schedule-lookup="scheduleLookup"></Schedule>
                </div>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "../parameter/Parameter";
    import Schedule from "../schedules/Schedule";

    export default {
        name: "Module",

        components: {
            Parameter,
            Schedule
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            collapseId: function () {
                return this.moduleName.replace(/ /g, '') + "_collapse"; //collapse id does not support whitespaces anymore
            },

            collapseIcon: function () {
                return this.collapseOpen === false ? '+' : '-';
            },

            isModuleActive: function () {
                if (this.moduleName === 'Module 1') return parseInt(this.parameters.p_78.value) !== 0;
                else if (this.moduleName === 'Module 2') return parseInt(this.parameters.p_81.value) !== 0;
                else if (this.moduleName === 'Module 3') return parseInt(this.parameters.p_84.value) !== 0;
            },

            isOpModeSchedule: function () {
                if (this.moduleName === 'Module 1') {
                    if (parseInt(this.parameters.p_78.value) === 1) return parseInt(this.parameters.p_101.value) === 2;
                    else if (parseInt(this.parameters.p_78.value) === 2) return parseInt(this.parameters.p_103.value) === 2;
                    else if (parseInt(this.parameters.p_78.value) === 3) return parseInt(this.parameters.p_104.value) === 2;
                } else if (this.moduleName === 'Module 2') {
                    if (parseInt(this.parameters.p_81.value) === 1) return parseInt(this.parameters.p_105.value) === 2;
                    else if (parseInt(this.parameters.p_81.value) === 2) return parseInt(this.parameters.p_107.value) === 2;
                    else if (parseInt(this.parameters.p_81.value) === 3) return parseInt(this.parameters.p_108.value) === 2;
                } else if (this.moduleName === 'Module 3') {
                    if (parseInt(this.parameters.p_84.value) === 1) return parseInt(this.parameters.p_109.value) === 2;
                    else if (parseInt(this.parameters.p_84.value) === 2) return parseInt(this.parameters.p_111.value) === 2;
                    else if (parseInt(this.parameters.p_84.value) === 3) return parseInt(this.parameters.p_112.value) === 2;
                }
            }
        },

        props: [
            "moduleName",
            "modulesLookup"
        ],

        data() {
            return {
                collapseOpen: false,
                scheduleLookup: {
                    "mon": [],
                    "tue": [],
                    "wed": [],
                    "thu": [],
                    "fri": [],
                    "sat": [],
                    "sun": [],
                }
            }
        },

        created() {
            if (this.moduleName === 'Module 1') {
                this.scheduleLookup.mon = ["p_113", "p_114", "p_115"];
                this.scheduleLookup.tue = ["p_116", "p_117", "p_118"];
                this.scheduleLookup.wed = ["p_119", "p_120", "p_121"];
                this.scheduleLookup.thu = ["p_122", "p_123", "p_124"];
                this.scheduleLookup.fri = ["p_125", "p_126", "p_127"];
                this.scheduleLookup.sat = ["p_128", "p_129", "p_130"];
                this.scheduleLookup.sun = ["p_131", "p_132", "p_133"];
            } else if (this.moduleName === 'Module 2') {
                this.scheduleLookup.mon = ["p_134", "p_135", "p_136"];
                this.scheduleLookup.tue = ["p_137", "p_138", "p_139"];
                this.scheduleLookup.wed = ["p_140", "p_141", "p_142"];
                this.scheduleLookup.thu = ["p_143", "p_144", "p_145"];
                this.scheduleLookup.fri = ["p_146", "p_147", "p_148"];
                this.scheduleLookup.sat = ["p_149", "p_150", "p_151"];
                this.scheduleLookup.sun = ["p_152", "p_153", "p_154"];
            } else if (this.moduleName === 'Module 3') {
                this.scheduleLookup.mon = ["p_155", "p_156", "p_157"];
                this.scheduleLookup.tue = ["p_158", "p_159", "p_160"];
                this.scheduleLookup.wed = ["p_161", "p_162", "p_163"];
                this.scheduleLookup.thu = ["p_164", "p_165", "p_166"];
                this.scheduleLookup.fri = ["p_167", "p_168", "p_169"];
                this.scheduleLookup.sat = ["p_170", "p_171", "p_172"];
                this.scheduleLookup.sun = ["p_173", "p_174", "p_175"];
            }
        },

        methods: {
            refreshSliders() {
                this.$root.$emit("refresh-slider");
            },

            shouldShow() {
                let parameters = Object.keys(this.parameters);

                return parameters.some(r => this.modulesLookup[this.moduleName].includes(r));
            },

            shouldRenderSettingsHeader(key) {
                return key === 'p_101' || key === 'p_105' || key === 'p_109' || key === 'p_103' || key === 'p_107' || key === 'p_111' || key === 'p_104'
                    || key === 'p_108' || key === 'p_112';
            },

            shouldRenderParameter(key) {
                if (this.modulesLookup[this.moduleName].indexOf(key) > -1) {
                    if (key === 'p_80') return parseInt(this.parameters.p_78.value) !== 3; //Don't display sensor during ON/OFF mode
                    else if (key === 'p_83') return parseInt(this.parameters.p_81.value) !== 3;
                    else if (key === 'p_86') return parseInt(this.parameters.p_84.value) !== 3;
                    else if (key === 'p_35' || key === 'p_36' || key === 'p_37' || key === 'p_102') {
                        return parseInt(this.parameters.p_101.value) !== 0; //Don't display logic if operation not active
                    } else if (key === 'p_87' || key === 'p_88' || key === 'p_89' || key === 'p_106') {
                        return parseInt(this.parameters.p_105.value) !== 0;
                    } else if (key === 'p_94' || key === 'p_95' || key === 'p_96' || key === 'p_110') {
                        return parseInt(this.parameters.p_109.value) !== 0;
                    } else if (key === 'p_39' || key === 'p_40' || key === 'p_63') {
                        return parseInt(this.parameters.p_103.value) !== 0;
                    } else if (key === 'p_91' || key === 'p_92' || key === 'p_93') {
                        return parseInt(this.parameters.p_107.value) !== 0;
                    } else if (key === 'p_98' || key === 'p_99' || key === 'p_100') {
                        return parseInt(this.parameters.p_111.value) !== 0;
                    } else if (key === 'p_38') { //Don't display T eco
                        return parseInt(this.parameters.p_101.value) !== 0 && parseInt(this.parameters.p_37.value) !== 0;
                    } else if (key === 'p_90') {
                        return parseInt(this.parameters.p_105.value) !== 0 && parseInt(this.parameters.p_89.value) !== 0;
                    } else if (key === 'p_97') {
                        return parseInt(this.parameters.p_109.value) !== 0 && parseInt(this.parameters.p_96.value) !== 0;
                    } else {
                        //Needed for checking schedule parameters
                        return this.scheduleLookup.mon.indexOf(key) === -1 && this.scheduleLookup.tue.indexOf(key) === -1 && this.scheduleLookup.wed.indexOf(key) === -1
                            && this.scheduleLookup.thu.indexOf(key) === -1 && this.scheduleLookup.fri.indexOf(key) === -1 && this.scheduleLookup.sat.indexOf(key) === -1
                            && this.scheduleLookup.sun.indexOf(key) === -1
                    }
                } else return false;
            }
        }
    }
</script>

<style scoped>
    .collapse-btn-style {
        padding: 0rem 0.4rem;
        margin: 0px 10px 0px 10px;
        min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
    }
</style>
