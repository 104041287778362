<template>
    <b-tabs lazy pills>
        <b-tab title="Mon">
            <b-form-group v-for="key in scheduleLookup.mon" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Tue">
            <b-form-group v-for="key in scheduleLookup.tue" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Wed">
            <b-form-group v-for="key in scheduleLookup.wed" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Thu">
            <b-form-group v-for="key in scheduleLookup.thu" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Fri">
            <b-form-group v-for="key in scheduleLookup.fri" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Sat">
            <b-form-group v-for="key in scheduleLookup.sat" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
        <b-tab title="Sun">
            <b-form-group v-for="key in scheduleLookup.sun" class="m-0 px-1 py-2">
                <Parameter :param-key="key" :param-value="parameters[key]"></Parameter>
            </b-form-group>
        </b-tab>
    </b-tabs>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "../parameter/Parameter";

    export default {
        name: "Schedule",

        components: {
            Parameter
        },

        props: [
            "scheduleLookup",
            "formInputs",
            "formLabelsBooleans",
        ],

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            }
        }
    }
</script>

<style scoped>

</style>
