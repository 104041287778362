<template>
    <b-tabs no-key-nav pills lazy card content-class="edit-section-tab">
        <b-tab key="1" title="General" active>
            <template slot="title"><i class="icon-settings px-2"></i></template>

            <GeneralSettings></GeneralSettings>
        </b-tab>

        <b-tab key="2" v-if="shouldShow('Settings')">
            <template slot="title"><i class="icon-wrench px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Settings</em></i></h4>

            <div v-if="shouldShow('Root Settings')" class="pb-3">
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Root Settings"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Transfer')">
                <strong>Heat Transfer</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Heat Transfer"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Pump')">
                <strong>Heat Pump</strong>
                <hr>
                <br>
                <Parameter v-if='categoriesLookup["Settings"]["Heat Pump"].indexOf("p_68") > -1' :key="'p_68'" :param-key="'p_68'"
                           :param-value="parameters['p_68']"></Parameter>

                <Parameter v-if='categoriesLookup["Settings"]["Heat Pump"].indexOf("p_69") > -1' :key="'p_69'" :param-key="'p_69'"
                           :param-value="parameters['p_69']"></Parameter>

                <div v-if="parameters['p_68'].value === 2" class="py-3">
                    <strong>Schedule</strong>
                    <hr>
                    <Schedule :schedule-lookup="scheduleLookup"></Schedule>
                </div>
            </div>
        </b-tab>

        <b-tab key="3" v-if="shouldShow('Modules')">
            <template slot="title"><i class="icon-layers px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Modules</em></i></h4>

            <Module :module-name="'Module 1'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-name="'Module 2'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-name="'Module 3'" :modules-lookup="this.categoriesLookup['Modules']"></Module>
        </b-tab>

        <b-tab key="4" v-if="shouldShow('Protections')">
            <template slot="title"><i class="icon-shield px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Protections</em></i></h4>

            <div v-if="shouldShow('System')">
                <strong>System</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["System"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Collector')">
                <br><strong>Collector</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Collector"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Recooling')">
                <br><strong>Recooling</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Recooling"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Frost')">
                <br><strong>Frost</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Frost"].indexOf(key) > -1
                           && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Seizing')">
                <br><strong>Seizing</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Seizing"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Storage Alarm')">
                <br><strong>Storage Alarm</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Storage Alarm"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>
        </b-tab>

        <b-tab key="5" v-if="shouldShow('Manual Operations')">
            <template slot="title"><i class="icon-game-controller px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Manual Operations</em></i></h4>

            <Parameter v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"
                       v-if='categoriesLookup["Manual Operations"].indexOf(key) > -1 && showManualOperationKey({key: key, deviceId: $route.params.id})'></Parameter>
        </b-tab>

        <b-tab key="6" v-if="shouldShow('Root Special Functions')">
            <template slot="title"><i class="icon-magic-wand px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Special Functions</em></i></h4>

            <div v-if="shouldShow('Root Special Functions')">
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Root Special Functions"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Datetime')">
                <br><strong>Datetime</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Datetime"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
    import Header from "@/components/layout/Header";
    import Module from "@/components/virtual-device/edit/modules/Module";
    import Parameter from "@/components/virtual-device/edit/parameter/Parameter";
    import Schedule from "@/components/virtual-device/edit/schedules/Schedule";
    import GeneralSettings from "@/components/virtual-device/edit/general-settings/GeneralSettings";
    import {mapGetters} from 'vuex';

    export default {
        name: "View1",

        components: {
            Header,
            Module,
            GeneralSettings,
            Parameter,
            Schedule
        },

        data() {
            return {
                scheduleLookup: {
                    "mon": [],
                    "tue": [],
                    "wed": [],
                    "thu": [],
                    "fri": [],
                    "sat": [],
                    "sun": [],
                },
                categoriesLookup: {
                    "Settings": {
                        "Root Settings": ["p_6", "p_7", "p_8", "p_9", "p_27", "p_28", "p_29", "p_30", "p_31", "p_32", "p_33", "p_34"],
                        "Heat Transfer": ["p_64", "p_65", "p_66", "p_67"],
                        "Heat Pump": ["p_41", "p_42", "p_43", "p_44", "p_45", "p_46", "p_47", "p_48", "p_49", "p_50", "p_51", "p_52", "p_53", "p_54",
                            "p_55", "p_56", "p_57", "p_58", "p_59", "p_60", "p_61", "p_68", "p_69"]
                    },
                    "Protections": {
                        "System": ["p_10", "p_11", "p_12"],
                        "Collector": ["p_13", "p_14", "p_15", "p_16"],
                        "Recooling": ["p_17", "p_18"],
                        "Frost": ["p_19", "p_20", "p_21"],
                        "Seizing": ["p_22", "p_23", "p_24"],
                        "Storage Alarm": ["p_25", "p_26"]
                    },
                    "Special Functions": {
                        "Root Special Functions": ["p_1"],
                        "Datetime": ["p_70", "p_71"]
                    },
                    "Manual Operations": ["p_72", "p_73", "p_74", "p_75"],
                    "Modules": {
                        "Module 1": ["p_78", "p_35", "p_36", "p_37", "p_38", "p_39", "p_40", "p_63", "p_79", "p_80", "p_101", "p_102", "p_103", "p_104", "p_113",
                            "p_114", "p_115", "p_116", "p_117", "p_118", "p_119", "p_120", "p_121", "p_122", "p_123", "p_124", "p_125", "p_126", "p_127",
                            "p_128", "p_129", "p_130", "p_131", "p_132", "p_133", "p_177"],
                        "Module 2": ["p_81", "p_82", "p_83", "p_87", "p_88", "p_89", "p_90", "p_91", "p_92", "p_93", "p_105", "p_106", "p_107", "p_108", "p_134",
                            "p_135", "p_136", "p_137", "p_138", "p_139", "p_140", "p_141", "p_142", "p_143", "p_144", "p_145", "p_146", "p_147", "p_148",
                            "p_149", "p_150", "p_151", "p_152", "p_153", "p_154", "p_178"],
                        "Module 3": ["p_84", "p_85", "p_86", "p_94", "p_95", "p_96", "p_97", "p_98", "p_99", "p_100", "p_109", "p_110", "p_111", "p_112", "p_155",
                            "p_156", "p_157", "p_158", "p_159", "p_160", "p_161", "p_162", "p_163", "p_164", "p_165", "p_166", "p_167", "p_168", "p_169",
                            "p_170", "p_171", "p_172", "p_173", "p_174", "p_175", "p_179"]
                    }
                }
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                showManualOperationKey: 'virtualDevice/showManualOperationKey'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            }
        },

        created() {
            this.scheduleLookup.mon = this.categoriesLookup["Settings"]["Heat Pump"].slice(0, 3);
            this.scheduleLookup.tue = this.categoriesLookup["Settings"]["Heat Pump"].slice(3, 6);
            this.scheduleLookup.wed = this.categoriesLookup["Settings"]["Heat Pump"].slice(6, 9);
            this.scheduleLookup.thu = this.categoriesLookup["Settings"]["Heat Pump"].slice(9, 12);
            this.scheduleLookup.fri = this.categoriesLookup["Settings"]["Heat Pump"].slice(12, 15);
            this.scheduleLookup.sat = this.categoriesLookup["Settings"]["Heat Pump"].slice(15, 18);
            this.scheduleLookup.sun = this.categoriesLookup["Settings"]["Heat Pump"].slice(18, 21);
        },

        methods: {
            shouldShow(categoryName) {
                let parameters = Object.keys(this.parameters);

                if (categoryName === "Root Settings" && this.categoriesLookup['Settings']['Root Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r));
                } else if (categoryName === "Heat " && this.categoriesLookup['Settings']['Thermostat'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Thermostat'].includes(r));
                } else if (categoryName === "Recycle" && this.categoriesLookup['Settings']['Recycle'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Recycle'].includes(r));
                } else if (categoryName === "Heat Transfer" && this.categoriesLookup['Settings']['Heat Transfer'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Heat Pump" && this.categoriesLookup['Settings']['Heat Pump'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r));
                } else if (categoryName === "System" && this.categoriesLookup['Protections']['System'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Collector" && this.categoriesLookup['Protections']['Collector'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Collector'].includes(r));
                } else if (categoryName === "Recooling" && this.categoriesLookup['Protections']['Recooling'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Recooling'].includes(r));
                } else if (categoryName === "Frost" && this.categoriesLookup['Protections']['Frost'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Frost'].includes(r));
                } else if (categoryName === "Seizing" && this.categoriesLookup['Protections']['Seizing'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Seizing'].includes(r));
                } else if (categoryName === "Storage Alarm" && this.categoriesLookup['Protections']['Storage Alarm'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Storage Alarm'].includes(r));
                } else if (categoryName === "Datetime" && this.categoriesLookup['Special Functions']['Datetime'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Datetime'].includes(r));
                } else if (categoryName === "Settings" && this.categoriesLookup['Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Modules" && this.categoriesLookup['Modules'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Modules']['Module 1'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 2'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 3'].includes(r));
                } else if (categoryName === "Protections" && this.categoriesLookup['Protections'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Manual Operations" && this.categoriesLookup['Manual Operations'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Manual Operations'].includes(r));
                } else if (categoryName === "Root Special Functions" && this.categoriesLookup['Special Functions']['Root Special Functions'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Root Special Functions'].includes(r));
                } else return false;
            },

            shouldRenderParameter(key) {

                if (key === 'p_11' || key === 'p_12') return parseInt(this.parameters.p_10.value) !== 0; //Don't display parameter if protection isn't active
                else if (key === 'p_14' || key === 'p_15' || key === 'p_16') return parseInt(this.parameters.p_13.value) !== 0;
                else if (key === 'p_18') return parseInt(this.parameters.p_17.value) !== 0;
                else if (key === 'p_20' || key === 'p_21') return parseInt(this.parameters.p_19.value) !== 0;
                else if (key === 'p_26') return parseInt(this.parameters.p_25.value) !== 0;
                else return true;

            }
        }
    }
</script>

<style scoped>

</style>
